import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Calendar } from "lucide-react"
import { DateTime } from "luxon"
import { roundedNow, useAutoQuery } from "../../utils"
import { getTopArtists, getTopSongs } from "../../db/DbService"
import { SongCard } from "../SongCard"
import { ArtistCard } from "../ArtistCard"

export function ThisWeekSoFarCard() {
    return (
        <Card>
            <CardHeader className="flex flex-col gap-1">
                <CardTitle className="flex items-center gap-2">
                    <Calendar className="h-6 w-6"/>
                    This Week So Far
                </CardTitle>
                <CardDescription>Discover your top artists and songs listened to so far this week.</CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col gap-2">
                <TopArtists/>
                <TopSongs/>
            </CardContent>
        </Card>
    )
}

function TopArtists() {
    const {data: groups, isPending} = useAutoQuery(getTopArtists, {
        start: DateTime.now().startOf("week"),
        end: roundedNow(),
        limit: 2
    })

    if(isPending || groups.length === 0) {
        return
    }

    return (
        <div className="grid grid-cols-2 gap-2">
            {groups.map(group => <ArtistCard artistId={group.entityId} key={group.entityId}/>)}
        </div>
    )
}

function TopSongs() {
    const {data: groups, isPending} = useAutoQuery(getTopSongs, {
        start: DateTime.now().startOf("week"),
        end: roundedNow(),
        limit: 2
    })

    if(isPending || groups.length === 0) {
        return
    }

    return (
        <div className="grid grid-cols-2 gap-2">
            {groups.map(group => <SongCard songId={group.entityId} key={group.entityId}/>)}
        </div>
    )
}
