import { ChartNoAxesColumn, Home, Search } from "lucide-react"
import { Link } from "react-router-dom"

export function MobileNav() {
    return (
        <div className="flex flex row items-center justify-around min-h-[3rem] w-full bg-white drop-shadow-3xl border-t">
            <Nav icon={Home} to="/home"/>
            <Nav icon={ChartNoAxesColumn} to="/top"/>
            <Nav icon={Search} to="/search"/>
        </div>
    )
}

function Nav({icon: Icon, to}) {
    return (
        <Link to={to} className="h-[50%] aspect-square">
            <Icon className="w-full h-full"/>
        </Link>
    )
}