import * as apiClient from "./ApiClient"
import { getUserDb } from "./ApiClient"
import * as playerUpdateStore from "../stores/PlayerStateStore"
import axios from "axios"

const httpClient = axios.create()

export async function pause() {
    await apiClient.pause()
    await wait(500)
    await playerUpdateStore.update()
}

export async function resume() {
    await apiClient.resume()
    await wait(500)
    await playerUpdateStore.update()
}

export async function skipToNext() {
    await apiClient.skipToNext()
    await wait(500)
    await playerUpdateStore.update()
}

export async function skipToPrevious() {
    await apiClient.skipToPrevious()
    await wait(500)
    await playerUpdateStore.update()
}

export async function play({songId}) {
    await apiClient.play({songId})
    await wait(500)
    await playerUpdateStore.update()
}

export async function readUserDbData() {
    const userDb = await getUserDb();
    const response = await httpClient.get(userDb.url, {
        responseType: "arraybuffer"
    })
    return new Uint8Array(response.data)
}

async function wait(ms) {
    await new Promise(resolve => setTimeout(resolve, 500));
}