import { Bubbles } from "./Bubbles"
import { useAlbums } from "../stores/AlbumStore"

export function AlbumBubbles({groups, size}) {
    const albumIds = groups.map(group => group.entityId);
    const results = useAlbums(albumIds);

    const albumsById = {};
    for (const result of results) {
        if (!result.isPending) {
            const artist = result.data;
            albumsById[artist.id] = artist;
        } else {
            return null
        }
    }

    const data = groups
        .map(group => {
            const album = albumsById[group.entityId];
            return {
                id: album.id,
                name: album.name,
                value: group.count,
                imageUrl: album.images[0]?.url,
                path: `/album/${album.id}`
            };
        })

    return <Bubbles data={data} size={size}/>
}