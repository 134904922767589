import { Link } from "react-router-dom"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { RouteIcon } from "lucide-react"
import { Button } from "../ui/button"

export function JourneyCard() {
    return (
        <Link to="/journey">
            <Card>
                <CardHeader className="flex flex-col gap-1">
                    <CardTitle className="flex items-center gap-2">
                        <RouteIcon className="h-6 w-6"/>
                        Your Journey
                    </CardTitle>
                    <CardDescription>Discover how your tastes have evolved</CardDescription>
                </CardHeader>
                <CardContent className="flex flex-col gap-2">
                    <p>Delve into your personal soundtrack, track the evolution of your taste, and revisit your favorite musical highlights.</p>
                    <Button className="w-full bg-grad bg-gradient-to-r from-green-400 to-blue-500">
                        <span className="text-white drop-shadow-xl">
                            Start Exploring
                        </span>
                    </Button>
                </CardContent>
            </Card>
        </Link>
    )
}