import tailwindConfig from "../../tailwind.config.js"
import resolveConfig from "tailwindcss/resolveConfig"
import { useMediaQuery } from "react-responsive"

const config = resolveConfig(tailwindConfig)

export function useBreakpoint(breakpointKey) {
    const bool = useMediaQuery({
        query: `(min-width: ${config.theme.screens[breakpointKey]})`,
    });
    const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
    return {
        [`is${capitalizedKey}`]: bool,
    };
}