import { Link, useLocation } from "react-router-dom"
import { useAutoQuery } from "../utils"
import React from "react"
import { useAlbum } from "../stores/AlbumStore"
import { searchWithReordering } from "../db/DbService"
import { SongCard } from "../components/SongCard"
import { ArtistCard } from "../components/ArtistCard"

export function SearchPage() {
    const location = useLocation()
    const query = new URLSearchParams(location.search).get("q")

    const {data: results, isPending} = useAutoQuery(searchWithReordering, query)

    if (isPending) {
        return
    }

    return (
        <div className="flex flex-col gap-8 w-full">
            <Header query={query}/>
            <Artists artists={results.artists}/>
            <Albums albums={results.albums}/>
            <Songs results={results.songs}/>
        </div>
    )

}

function Header({query}) {
    return (
        <div className="flex items-center">
            <div>
                <h1 className="text-3xl font-bold">Search Results</h1>
                <p className="text-lg text-gray-500 dark:text-gray-400">for "{query}"</p>
            </div>
        </div>
    )
}

function Artists({artists}) {
    if (artists.length === 0) {
        return null
    }

    return (
        <section className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">Artists</h2>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                {artists
                    .slice(0, 8)
                    .map(result => <ArtistCard artistId={result.id} key={result.id}/>)
                }
            </div>
        </section>
    )
}

function Albums({albums}) {
    if (albums.length === 0) {
        return null
    }

    return (
        <section className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">Albums</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                {albums
                    .slice(0, 10)
                    .map(result => <Album result={result} key={result.id}/>)
                }
            </div>
        </section>
    )
}

function Album({result}) {
    const {data: album, isPending} = useAlbum(result.id)

    if(isPending) {
        return
    }

    return (
        <Link to={`/album/${album.id}`} key={album.id} className="flex flex-col items-center text-center gap-2">
            <div className="relative">
                <img
                    src={album.images[0]?.url}
                    alt={album.name}
                    className="w-full aspect-square object-cover rounded-md shadow-lg"
                />
                <div className="absolute bottom-2 right-2 bg-primary text-primary-foreground px-2 py-1 rounded-full text-xs font-semibold">
                    {album.releaseDate.year}
                </div>
            </div>
            <div className="flex flex-col">
                <h4 className="font-semibold text-sm line-clamp-1">{album.name}</h4>
                <p className="text-xs line-clamp-1">{album.artistCredit.segments.map(segment => segment.text).join("")}</p>
            </div>
        </Link>
    )
}

function Songs({results}) {
    if (results.length === 0) {
        return null
    }
    
    return (
        <section className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">Songs</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                {results
                    .slice(0, 10)
                    .map(result => <SongCard songId={result.id} key={result.id}/>)
                }
            </div>
        </section>
    )
}