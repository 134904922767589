import { useAutoQuery } from "../../utils"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { MoonStar } from "lucide-react"
import { SongCard } from "../SongCard"
import { getOneHitWonders } from "../../db/DbService"

export function OneHitWondersCard() {
    const {data: groups, isPending} = useAutoQuery(getOneHitWonders, {limit: 4})

    if(isPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-col gap-1">
                <CardTitle className="flex items-center gap-2">
                    <MoonStar className="h-6 w-6"/>
                    One-Hit Wonders
                </CardTitle>
                <CardDescription>Artists who made a lasting impact with a single hit.</CardDescription>
            </CardHeader>
            <CardContent className="grid grid-cols-2 gap-2">
                {groups.map(group => <SongCard songId={group.entityId} key={group.entityId}/>)}
            </CardContent>
        </Card>
    )
}
