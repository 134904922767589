import { Button } from "./ui/button"
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "lucide-react"
import { Link, useLocation } from "react-router-dom"

export function Pagination({page, pageCount}) {
    if(pageCount === 1) {
        return null
    }

    let minDisplayedPage = page - 1;
    let maxDisplayedPage = page + 2;

    while(maxDisplayedPage > pageCount) {
        maxDisplayedPage--;
        minDisplayedPage--;
    }

    while(minDisplayedPage < 1) {
        minDisplayedPage++;
        maxDisplayedPage++;
    }

    minDisplayedPage = Math.max(0, minDisplayedPage)
    maxDisplayedPage = Math.min(pageCount - 1, maxDisplayedPage)

    const pagesToDisplay = []
    for(let i = minDisplayedPage; i <= maxDisplayedPage; i++) {
        pagesToDisplay.push(i)
    }

    return (
        <div className="flex flex-row gap-1 lg:gap-2 items-center justify-center w-full">
            <PaginationButton toPage={1} enabled={page > 1}>
                <ChevronsLeft className="w-5 h-5"/>
            </PaginationButton>
            <PaginationButton toPage={Math.max(1, page - 1)}>
                <ChevronLeft className="w-5 h-5"/>
            </PaginationButton>
            {pagesToDisplay.map(i => (
                <PaginationButton toPage={i} key={i}>
                    {i}
                </PaginationButton>
            ))}
            <PaginationButton toPage={Math.min(pageCount, page + 1)}>
                <ChevronRight className="w-5 h-5"/>
            </PaginationButton>
            <PaginationButton toPage={pageCount}>
                <ChevronsRight className="w-5 h-5"/>
            </PaginationButton>
        </div>
    )

}

function PaginationButton({children, toPage}) {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    queryParams.set("page", toPage)

    return (
        <Link to={`${location.pathname}?${queryParams.toString()}`}>
            <Button variant="outline">
                {children}
            </Button>
        </Link>
    )
}