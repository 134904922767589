import { Mutex } from "async-mutex"

export class StatCalculator {
    constructor(calculationFunction) {
        this.calculationFunction = calculationFunction;
        this.mutex = new Mutex();
        this.alreadyCalculated = false;
    }

    async ensureCalculated() {
        if (this.alreadyCalculated) {
            return;
        }

        const release = await this.mutex.acquire();
        try {
            if (this.alreadyCalculated) {
                return;
            }

            await this.calculationFunction()
            this.alreadyCalculated = true;
        } catch (error) {
            console.error(`Error calculating stat: `, error);
        } finally {
            release();
        }
    }
}