import { TopAlbums } from "../../components/TopAlbums"
import { TopSongs } from "../../components/TopSongs"
import React from "react"
import { epoch, roundedNow } from "../../utils"

export function StatsTab({artistId}) {
    const start = epoch()
    const end = roundedNow()

    return (
        <div className="grid sm:grid-colds-1 md:grid-cols-2 gap-4 w-full">
            <TopAlbums artistId={artistId} start={start} end={end}/>
            <TopSongs artistId={artistId} start={start} end={end}/>
        </div>
    )
}