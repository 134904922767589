import { useAutoQuery } from "../../utils"
import { getBlastFromThePastSongs } from "../../db/DbService"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Rocket } from "lucide-react"
import { SongCard } from "../SongCard"

export function BlastFromThePastCard() {
    const { data: groups, isPending } = useAutoQuery(getBlastFromThePastSongs, { limit: 4 })

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-col gap-1">
                <CardTitle className="flex items-center gap-2">
                    <Rocket className="h-6 w-6"/>
                    Blast from the Past
                </CardTitle>
                <CardDescription>Rediscover songs you once loved but may have forgotten.</CardDescription>
            </CardHeader>
            <CardContent className="grid grid-cols-2 gap-2">
                {groups.map(group => <SongCard songId={group.entityId} key={group.entityId}/>)}
            </CardContent>
        </Card>
    )
}
