import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { Flame } from "lucide-react"
import React from "react"
import { formatDateShort, useAutoQuery } from "../utils"
import { getLongestArtistStreak } from "../db/DbService"

export function ArtistLongestStreakCard({artistId}) {
    const {data: streak, isPending} = useAutoQuery(getLongestArtistStreak, {
        artistId: artistId
    })

    if (isPending || streak == null) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Longest Streak</CardTitle>
                <Flame className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold text-wrap line-clamp-1">{streak.length} days</div>
                <p className="text-xs text-muted-foreground">{formatDateShort(streak.start)} to {formatDateShort(streak.end)}</p>
            </CardContent>
        </Card>
    )
}
