import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { HeadphonesIcon } from "lucide-react"
import React from "react"
import { epoch, formatCount, roundedNow, useAutoQuery } from "../utils"
import { getArtistSummary } from "../db/DbClient"

export function ArtistTotalListensCard({artistId}) {
    const start = epoch()
    const end = roundedNow()
    const recentStart = end.minus({days: 30})

    const {
        data: allTimeSummary,
        isPending: allTimeSummaryPending,
    } = useAutoQuery(getArtistSummary, {artistId, start, end})
    const {
        data: recentSummary,
        isPending: recentSummaryPending,
    } = useAutoQuery(getArtistSummary, {artistId, start: recentStart, end})

    if (allTimeSummaryPending || recentSummaryPending) {
        return null
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Total Listens</CardTitle>
                <HeadphonesIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{formatCount(allTimeSummary.listenCount)}</div>
                <p className="text-xs text-muted-foreground">+{formatCount(recentSummary.listenCount)} recently</p>
            </CardContent>
        </Card>
    )
}