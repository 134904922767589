import { Button } from "./ui/button"
import { PlayCircleIcon } from "lucide-react"
import React from "react"
import { play } from "../api/ApiService"
import { useSong } from "../stores/SongStore"
import { useStoreWithEqualityFn } from "zustand/traditional"
import { playerStateStore } from "../stores/PlayerStateStore"
import { OpenInSpotifyButton } from "./OpenInSpotifyButton"

export function PlaySongOnSpotifyButton({songId}) {
    const {data: song, isPending} = useSong(songId)
    const playerState = useStoreWithEqualityFn(playerStateStore)

    if(isPending) {
        return
    }

    if(song.spotifyUrl !== null) {
        if(playerState.canPlay) {
            return (
                <Button
                    className="bg-spotify hover:bg-spotify-dark flex flex-row gap-1 w-fit"
                    onClick={() => play({songId})}>
                    <PlayCircleIcon/>
                    Play on Spotify
                </Button>
            )
        } else {
            return <OpenInSpotifyButton url={song.spotifyUrl}/>
        }
    }
}