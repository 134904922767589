import { Link } from "react-router-dom"
import React from "react"
import { useArtist } from "../stores/ArtistStore"

export function ArtistCredit({artistCredit}) {
    return (
        <div className="flex flex-row flex-wrap gap-1 text-xl text-gray-600 dark:text-gray-300">
            {artistCredit.segments.map((segment, index) => <Segment segment={segment} key={index}/>)}
        </div>
    )
}

export function Segment({ segment }) {
    if (segment.artistId) {
        return <ArtistSegment segment={segment}/>
    } else {
        return <TextSegment segment={segment}/>
    }
}

function ArtistSegment({ segment }) {
    const {data: artist} = useArtist(segment.artistId)

    return (
        <Link to={`/artist/${segment.artistId}`} className="hover:text-indigo-700 dark:hover:text-purple-400 transition-colors flex flex-row items-center gap-1">
            <img src={artist?.images[0]?.url} className="h-[1.25rem] aspect-square object-cover rounded-full" alt={artist?.name}/>
            <span className="line-clamp-1">{segment.text}</span>
        </Link>
    )
}

function TextSegment({ segment }) {
    return (
        <span className="line-clamp-1">{segment.text}</span>
    )
}