import { Link } from "react-router-dom"
import { BarChart3, ClockIcon } from "lucide-react"
import { formatCount, formatListenDuration, getFlattenedArtistCredit } from "../utils"
import React from "react"
import { Badge } from "./ui/badge"
import { useSong } from "../stores/SongStore"

export function SongWithStatsCard({group}) {
    const {data: song, isPending} = useSong(group.entityId)

    if (isPending) {
        return
    }

    const artistCreditName = getFlattenedArtistCredit(song.artistCredit)

    return (
        <Link to={`/song/${song.id}`} className="flex flex-col">
            <div className="rounded-t-md border-t border-x">
                <img src={song.images[0]?.url} alt={song.name} className="rounded-t-md object-cover aspect-square"/>
            </div>
            <div className="flex flex-col gap-1 border rounded-b-md p-2">
                <span className="text-md font-semibold text-nowrap truncate">{song.name}</span>
                <span className="text-sm text-nowrap truncate">{artistCreditName}</span>
                <Badge variant="secondary">
                    <BarChart3 className="w-3 h-3"/>
                    <span className="text-xs">{formatCount(group.count)} plays</span>
                </Badge>
                <Badge variant="secondary">
                    <ClockIcon className="w-3 h-3"/>
                    <span className="text-xs">{formatListenDuration(group.durationMs)}</span>
                </Badge>
            </div>
        </Link>
    )
}