import React from "react"
import { Link, useParams } from "react-router-dom"
import { getSongAlbums } from "../api/ApiClient"
import { ArtistCredit } from "../components/ArtistCredit"
import {
    epoch,
    formatDateLong,
    formatListenDuration,
    formatReleaseDate,
    formatTrackDuration,
    roundedNow,
    useAutoQuery,
    useIsMobile,
} from "../utils"
import { CalendarIcon, Clock3Icon, HeadphonesIcon, SparklesIcon, TrendingUpIcon } from "lucide-react"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { useSong } from "../stores/SongStore"
import { getSongSummary } from "../db/DbClient"
import { PlaySongOnSpotifyButton } from "../components/PlaySongOnSpotifyButton"

function Header({songId}) {
    const isMobile = useIsMobile()

    if(isMobile) {
        return <MobileHeader songId={songId}/>
    } else {
        return <DesktopHeader songId={songId}/>
    }
}

function DesktopHeader({songId}) {
    const {data: song, isPending: recordingPending} = useSong(songId)
    const {data: albums} = useAutoQuery(getSongAlbums, songId)

    if (recordingPending) {
        return null
    }

    const album = albums?.[0]

    return (
        <div className="flex flex-col md:flex-row gap-6 items-start">
            <img
                src={song.images[0]?.url}
                alt={song.name}
                className="w-64 h-64 object-cover rounded-lg shadow-lg"
            />
            <div className="flex flex-col gap-2">
                <h1 className="text-3xl md:text-4xl font-bold">{song.name}</h1>
                <ArtistCredit artistCredit={song.artistCredit}/>
                {album != null ? <AlbumCredit album={album}/> : null}
                <PlaySongOnSpotifyButton songId={song.id}/>
                <ReleaseDate releaseDate={song.releaseDate}/>
                <div className="flex items-center text-sm text-gray-600">
                    <Clock3Icon className="w-4 h-4 mr-1"/>
                    {formatTrackDuration(song.durationMs)}
                </div>
            </div>
        </div>
    )
}

function MobileHeader({songId}) {
    const {data: song, isPending: recordingPending} = useSong(songId)
    const {data: albums} = useAutoQuery(getSongAlbums, songId)

    if (recordingPending) {
        return null
    }

    const album = albums?.[0]

    return (
        <div className="flex flex-col gap-4">
            <img
                src={song.images[0]?.url}
                alt={song.name}
                className="w-full aspect-square object-cover rounded-lg shadow-lg"
            />
            <div className="flex flex-col gap-2">
                <h1 className="text-3xl md:text-4xl font-bold">{song.name}</h1>
                <ArtistCredit artistCredit={song.artistCredit}/>
                {album != null ? <AlbumCredit album={album}/> : null}
                <PlaySongOnSpotifyButton songId={song.id}/>
                <ReleaseDate releaseDate={song.releaseDate}/>
                <div className="flex items-center text-sm text-gray-600">
                    <Clock3Icon className="w-4 h-4 mr-1"/>
                    {formatTrackDuration(song.durationMs)}
                </div>
            </div>
        </div>
    )
}

function ReleaseDate({releaseDate}) {
    let formatted = formatReleaseDate(releaseDate)

    if (formatted === null) {
        return null
    }

    return (
        <div className="flex items-center text-sm text-gray-600">
            <CalendarIcon className="w-4 h-4 mr-1"/>
            {formatted}
        </div>
    )
}

function Summary({songId}) {
    return (
        <div className="grid w-full gap-4 grid-cols-2 lg:grid-cols-4">
            <ListenCountSummaryCard songId={songId}/>
            <ListeningTimeSummaryCard songId={songId}/>
            <RankSummaryCard songId={songId}/>
            <FirstListenSummaryCard songId={songId}/>
        </div>
    )
}

function ListenCountSummaryCard({songId}) {
    const start = epoch()
    const end = roundedNow()
    const recentStart = roundedNow().minus({days: 30})
    const {
        data: allTimeSummary,
        isPending: allTimeSummaryPending,
    } = useAutoQuery(getSongSummary, {songId, start, end})
    const {
        data: recentSummary,
        isPending: recentSummaryPending,
    } = useAutoQuery(getSongSummary, {songId, start: recentStart, end})

    if (allTimeSummaryPending || recentSummaryPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Total Listens</CardTitle>
                <HeadphonesIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{allTimeSummary.listenCount}</div>
                <p className="text-xs text-muted-foreground">+{recentSummary.listenCount} recently</p>
            </CardContent>
        </Card>
    )
}

function ListeningTimeSummaryCard({songId}) {
    const start = epoch()
    const end = roundedNow()
    const recentStart = roundedNow().minus({days: 30})
    const {
        data: allTimeSummary,
        isPending: allTimeSummaryPending,
    } = useAutoQuery(getSongSummary, {songId, start, end})
    const {
        data: recentSummary,
        isPending: recentSummaryPending,
    } = useAutoQuery(getSongSummary, {songId, start: recentStart, end})

    if (allTimeSummaryPending || recentSummaryPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Listening Time</CardTitle>
                <Clock3Icon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{formatListenDuration(allTimeSummary.durationMs)}</div>
                <p className="text-xs text-muted-foreground">+{formatListenDuration(recentSummary.durationMs)} from
                    last month</p>
            </CardContent>
        </Card>
    )
}

function RankSummaryCard({songId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: summary, isPending} = useAutoQuery(getSongSummary, {songId, start, end})

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Track Rank</CardTitle>
                <TrendingUpIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">#{summary.rank}</div>
                <p className="text-xs text-muted-foreground">Your most listened track</p>
            </CardContent>
        </Card>
    )
}

function FirstListenSummaryCard({songId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: summary, isPending} = useAutoQuery(getSongSummary, {songId, start, end})

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Discovery</CardTitle>
                <SparklesIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{formatDateLong(summary.firstListen)}</div>
                <p className="text-xs text-muted-foreground">Your first listen</p>
            </CardContent>
        </Card>
    )
}

function AlbumCredit({album}) {
    return (
        <div className="flex flex-row items-center gap-1 text-lg text-gray-500 dark:text-gray-400">
            <p className="text-nowrap">From the album</p>
            <Link to={`/album/${album.id}`}
                  className="hover:text-indigo-700 dark:hover:text-purple-400 transition-colors">{album.name}</Link>
        </div>
    )
}

export function SongPage() {
    const {songId} = useParams()

    return (
        <div className="flex flex-col items-center gap-4 w-full">
            <Header songId={songId}/>
            <Summary songId={songId}/>
        </div>
    );
}
