import { formatDateLong } from "../../utils"

export function TimelineTab({artistId}) {
    return (
        <div>
            <Event date={new Date()}>
                <span>Now!</span>
            </Event>
        </div>
    )
}

function Event({children, date}) {
    return (
        <div className="flex flex-row w-full items-center gap-2">
            <div className="flex flex-row-reverse w-[20%]">
                <span>{formatDateLong(date)}</span>
            </div>
            <div className="w-12 h-12 rounded-full bg-red-500"></div>
            {children}
        </div>
    )
}