import { Bubbles } from "./Bubbles"
import { useSongs } from "../stores/SongStore"

export function SongBubbles({groups, size}) {
    const songIds = groups.map(group => group.entityId);
    const results = useSongs(songIds);

    const songsById = {};
    for (const result of results) {
        if (!result.isPending) {
            const artist = result.data;
            songsById[artist.id] = artist;
        } else {
            return null
        }
    }

    const data = groups
        .map(group => {
            const song = songsById[group.entityId];
            return {
                id: song.id,
                name: song.name,
                value: group.count,
                imageUrl: song.images[0]?.url,
                path: `/song/${song.id}`
            };
        })

    return <Bubbles data={data} size={size}/>
}