import React from "react"
import { MobilePlayerBar } from "./PlayerBar"
import { MobileNav } from "./MobileNav"

export function MobileLayout({children}) {
    return (
        <div className="flex flex-col items-center w-full h-[100dvh] bg-purple-50">
            <div className="flex flex-col flex-grow items-center w-full overflow-y-scroll">
                <main className="w-full max-w-6xl p-2 flex-grow">
                    {children}
                </main>
            </div>
            <MobilePlayerBar/>
            <MobileNav/>
        </div>
    );
}
