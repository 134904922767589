import React from "react"
import { useAutoQuery } from "../../utils"
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card"
import { getTopDecades } from "../../db/DbService"
import { useSong } from "../../stores/SongStore"
import { Hourglass } from "lucide-react"
import { Link } from "react-router-dom"

export function ByTheDecadeCard({start, end}) {
    const {data: groups, isPending} = useAutoQuery(getTopDecades, {
        start,
        end,
        limit: 4,
        limitPerDecade: 4
    })

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle className="flex items-center gap-2">
                    <Hourglass className="h-6 w-6"/>
                    By the Decade
                </CardTitle>
            </CardHeader>
            <CardContent className="grid grid-cols-2 gap-2">
                {groups.map(group => <Decade group={group} key={group.entityId}/>)}
            </CardContent>
        </Card>
    );
}

function Decade({group}) {
    return (
        <div className="flex flex-col items-center gap-1">
            <DecadeImage group={group}/>
            <h4 className="font-semibold text-sm line-clamp-1">{group.entityId}s</h4>
        </div>
    )
}

function DecadeImage({group}) {
    return (
        <div className="grid grid-cols-2 w-full aspect-square overflow-hidden rounded-md">
            {group.songs.map(song => <SongImage songId={song.songId}/>)}
        </div>
    )
}

function SongImage({songId}) {
    const {data: song, isPending} = useSong(songId)

    if (isPending) {
        return
    }

    return (
        <Link to={`/song/${songId}`}>
            <img
                src={song.images[0].url}
                alt={song.name}
                className="aspect-square object-cover"
            />
        </Link>
    )
}