import { useStoreWithEqualityFn } from "zustand/traditional"
import { playerStateStore } from "../stores/PlayerStateStore"
import { useSong } from "../stores/SongStore"
import { Button } from "./ui/button"
import { Calendar, Headphones, Pause, Play, SkipBack, SkipForward, Trophy } from "lucide-react"
import { Progress } from "./ui/progress"
import { Link } from "react-router-dom"
import { pause, resume, skipToNext, skipToPrevious } from "../api/ApiService"
import { epoch, formatDateShort, formatTrackDuration, roundedNow, useAutoQuery } from "../utils"
import { getSongSummary } from "../db/DbClient"
import ordinal from "ordinal"

export function DesktopPlayerBar() {
    const state = useStoreWithEqualityFn(playerStateStore)
    const {data: song, isPending} = useSong(state.songId)

    if(isPending) {
        return
    }

    const progress = state.positionMs / song.durationMs

    return (
        <div className="w-full bg-background border-t-2 flex justify-center">
            <div className="flex flex-col items-center gap-2 w-full max-w-6xl m-2">
                <div className="flex items-center justify-between w-full">
                    <Link to={`/song/${song.id}`}>
                        <div className="flex items-center gap-4 max-w-lg">
                            <img
                                src={song.images[0]?.url}
                                alt={song.name}
                                className="w-12 h-12 rounded"
                            />
                            <div>
                                <p className="font-semibold line-clamp-1">{song.name}</p>
                                <p className="text-sm text-muted-foreground line-clamp-1">{song.artistCredit.segments.map(segment => segment.text).join("")}</p>
                            </div>
                        </div>
                    </Link>
                    <Stats songId={song.id}/>
                    <div className="flex items-center space-x-4">
                        <SkipToPreviousButton state={state}/>
                        {state.paused ? <PlayButton state={state}/> : <PauseButton state={state}/> }
                        <SkipToNextButton state={state}/>
                    </div>
                </div>
                <div className="flex flex-row gap-2 w-full items-center">
                    <span className="text-sm text-muted-foreground">{formatTrackDuration(state.positionMs)}</span>
                    <Progress value={progress * 100} className="h-1 w-full"/>
                    <span className="text-sm text-muted-foreground">{formatTrackDuration(song.durationMs)}</span>
                </div>
            </div>
        </div>
    )
}

export function MobilePlayerBar() {
    const state = useStoreWithEqualityFn(playerStateStore)
    const {data: song, isPending} = useSong(state.songId)

    if(isPending) {
        return
    }

    const progress = state.positionMs / song.durationMs

    return (
        <div className="w-[97%] bg-background flex justify-center items-center m-1 rounded-xl overflow-hidden flex-shrink-0">
            <div className="flex flex-col items-center w-[97%]">
                <div className="flex items-center justify-between w-full p-1">
                    <Link to={`/song/${song.id}`}>
                        <div className="flex items-center gap-2 max-w-lg">
                            <img
                                src={song.images[0]?.url}
                                alt={song.name}
                                className="h-[4dvh] aspect-square rounded"
                            />
                            <div>
                                <p className="text-sm font-semibold line-clamp-1">{song.name}</p>
                                <p className="text-xs text-muted-foreground line-clamp-1">{song.artistCredit.segments.map(segment => segment.text).join("")}</p>
                            </div>
                        </div>
                    </Link>
                    <div className="flex items-center gap-1">
                        <SkipToPreviousButton state={state}/>
                        {state.paused ? <PlayButton state={state}/> : <PauseButton state={state}/> }
                        <SkipToNextButton state={state}/>
                    </div>
                </div>
                <Progress value={progress * 100} className="h-0.5 w-[101%]"/>
            </div>
        </div>
    )
}

function Stats({songId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: summary, isPending} = useAutoQuery(getSongSummary, {songId, start, end})

    if(isPending) {
        return
    }

    return (
        <div className="flex flex-row justify-between gap-2 text-sm text-muted-foreground">
            <div className="flex flex-row gap-1 items-center">
                <Headphones className="h-4 w-4"/>
                {ordinal(summary.listenCount + 1)} listen
            </div>
            <div className="flex flex-row gap-1 items-center">
                <Trophy className="h-4 w-4"/>
                Ranked #{summary.rank}
            </div>
            <div className="flex flex-row gap-1 items-center">
                <Calendar className="h-4 w-4"/>
                {formatDateShort(summary.firstListen)}
            </div>
        </div>
    )
}

function PlayButton({state}) {
    return (
        <Button variant="ghost" size="icon" onClick={resume} disabled={!state.canResume}>
            <Play className="h-5 w-5"/>
        </Button>
    )
}

function PauseButton({state}) {
    return (
        <Button variant="ghost" size="icon" onClick={pause} disabled={!state.canPause}>
            <Pause className="h-5 w-5"/>
        </Button>
    )
}

function SkipToNextButton({state}) {
    return (
        <Button variant="ghost" size="icon" onClick={skipToNext} disabled={!state.canSkipToNext}>
            <SkipForward className="h-5 w-5"/>
        </Button>
    )
}

function SkipToPreviousButton({state}) {
    return (
        <Button variant="ghost" size="icon" onClick={skipToPrevious} disabled={!state.canSkipToPrevious}>
            <SkipBack className="h-5 w-5"/>
        </Button>
    )
}