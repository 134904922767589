import { useArtist } from "../stores/ArtistStore"
import { Link } from "react-router-dom"
import { Card, CardContent } from "./ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar"
import React from "react"
import { Badge } from "./ui/badge"

export function ArtistCard({artistId}) {
    const {data: artist, isPending} = useArtist(artistId)

    if(isPending) {
        return
    }

    return (
        <Link to={`/artist/${artist.id}`}>
            <Card className="p-2">
                <CardContent className="flex flex-col items-center gap-1 w-full">
                    <Avatar className="w-[75%] aspect-square">
                        <AvatarImage src={artist.images[0]?.url} alt={artist.name}/>
                        <AvatarFallback>{artist.name[0]}</AvatarFallback>
                    </Avatar>
                    <div className="flex flex-col items-center w-full max-w-full">
                        <h3 className="font-semibold text-lg line-clamp-1">{artist.name}</h3>
                        <div className="flex flex-row w-full overflow-x-hidden relative h-[1.5rem]">
                            <div className="flex flex-row gap-1 mx-auto">
                                {artist.genres.map(genre => <GenreBadge genre={genre} key={genre.id}/>)}
                            </div>
                            <div className="absolute right-0 top-0 h-full w-8 bg-gradient-to-l from-background to-transparent pointer-events-none" />
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Link>
    )
}

function GenreBadge({genre}) {
    return (
        <Badge className="line-clamp-1 flex-shrink-0 h-fit" variant="outline">
            {genre.name}
        </Badge>
    )
}
