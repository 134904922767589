import * as d3 from "d3"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export function Bubbles({ data, size }) {
    const [nodes, setNodes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const scale = Math.sqrt(2);
        const layoutSize = size * scale;
        const offset = (layoutSize - size) / 2;

        const hierarchy = d3
            .hierarchy({ children: data })
            .sum((d) => d.value)
            .sort((a, b) => b.value - a.value);

        const packGenerator = d3.pack().size([layoutSize, layoutSize]);
        const root = packGenerator(hierarchy);
        const initialNodes = root.descendants().slice(1);

        const simulation = d3.forceSimulation(initialNodes)
            .force("center", d3.forceCenter(layoutSize / 2, layoutSize / 2))
            .force("charge", d3.forceManyBody().strength(5))
            .force("collide", d3.forceCollide().radius(d => d.r + 1).strength(1))
            .force("x", d3.forceX(layoutSize / 2).strength(0.1))
            .force("y", d3.forceY(layoutSize / 2).strength(0.1))
            .stop();

        for (let i = 0; i < 300; ++i) simulation.tick();

        initialNodes.forEach(node => {
            node.x -= offset;
            node.y -= offset;
        });

        setNodes([...initialNodes]);

        return () => simulation.stop();
    }, [data, size]);

    const handleNodeClick = (node, event) => {
        event.preventDefault();
        navigate(node.data.path);
    };

    if (nodes.length === 0) return null;

    return (
        <svg width={size} height={size} style={{ display: "inline-block", overflow: "hidden" }}>
            <defs>
                {nodes.map(node => (
                    <clipPath key={`clip-${node.data.id}`} id={`clip-${node.data.id}`}>
                        <circle cx={node.x} cy={node.y} r={node.r} />
                    </clipPath>
                ))}
            </defs>

            <g mask="url(#edge-fade)">
                {nodes.map(node => (
                    <g
                        key={node.data.id}
                        onClick={(e) => handleNodeClick(node, e)}
                        style={{ cursor: 'pointer' }}
                    >
                        <circle
                            cx={node.x}
                            cy={node.y}
                            r={node.r}
                            fill="#B794F4"
                            fillOpacity={0.2}
                        >
                            <title>{node.data.name}</title>
                        </circle>
                        <image
                            href={node.data.imageUrl}
                            x={node.x - node.r}
                            y={node.y - node.r}
                            width={node.r * 2}
                            height={node.r * 2}
                            clipPath={`url(#clip-${node.data.id})`}
                            preserveAspectRatio="xMidYMid slice"
                            style={{ pointerEvents: 'none' }}
                        />
                    </g>
                ))}
            </g>
        </svg>
    );
}