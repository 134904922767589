import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table"
import React from "react"
import { formatCount, useAutoQuery } from "../utils"
import { useNavigate } from "react-router-dom"
import { getTopGenres } from "../db/DbClient"
import { useGenre } from "../stores/GenreStore"

export function TopGenre({listenGroup, index}) {
    const navigate = useNavigate()
    const {data: genre, isPending} = useGenre(listenGroup.entityId)

    if (isPending) {
        return
    }

    return (
        <TableRow onClick={() => navigate(`/genre/${genre.id}`)} className="cursor-pointer">
            <TableCell className="font-medium">{index + 1}</TableCell>
            <TableCell className="flex flex-row gap-2 items-center">{genre.name}</TableCell>
            <TableCell className="text-right">{formatCount(listenGroup.count)}</TableCell>
        </TableRow>
    )
}

export function TopGenres({start, end}) {
    const {data: listenGroups, isPending} = useAutoQuery(getTopGenres, {
        start,
        end,
        limit: 10,
    })

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Top Genres</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[50px]">#</TableHead>
                            <TableHead>Genre</TableHead>
                            <TableHead className="text-right">Plays</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {listenGroups.map((listenGroup, index) =>
                            <TopGenre listenGroup={listenGroup} index={index} key={listenGroup.entityId}/>)}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )

}