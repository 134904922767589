import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { TrendingUpIcon } from "lucide-react"
import React from "react"
import { epoch, roundedNow, useAutoQuery } from "../utils"
import { getArtistRank } from "../db/DbClient"
import { Link } from "react-router-dom"

export function ArtistRankCard({artistId}) {
    const start = epoch()
    const end = roundedNow()

    const {data: rank, isPending} = useAutoQuery(getArtistRank, {artistId, start, end})

    if (isPending) {
        return null
    }

    return (
        <Link to={`/top?rank=${rank}`}>
            <Card>
                <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle className="text-sm font-medium">Artist Rank</CardTitle>
                    <TrendingUpIcon className="w-4 h-4 text-muted-foreground"/>
                </CardHeader>
                <CardContent>
                    <div className="text-2xl font-bold">#{rank}</div>
                    <p className="text-xs text-muted-foreground">Your most listened artist</p>
                </CardContent>
            </Card>
        </Link>
    )
}