import React from "react"
import { startSpotifyLogin } from "../api/ApiClient"
import { Button } from "./ui/button"

export function AppleMusicLoginButton() {
    const handleLogin = async () => {
        try {
            let response = await startSpotifyLogin()
            window.location.href = response.url
        } catch (error) {
            console.error("Login failed:", error)
        }
    }

    return (
        <Button onClick={handleLogin} variant="outline" className="text-md">
            Login with Apple Music
        </Button>
    )
}
