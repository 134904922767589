import React from "react"
import { useIsMobile } from "../utils"
import { MobileLayout } from "./MobileLayout"
import { DesktopLayout } from "./DesktopLayout"

export function Layout({children}) {
    const isMobile = useIsMobile()

    if(isMobile) {
        return (
            <MobileLayout>
                {children}
            </MobileLayout>
        )
    } else {
        return (
            <DesktopLayout>
                {children}
            </DesktopLayout>
        )
    }
}
