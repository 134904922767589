import { create, keyResolver, windowedFiniteBatchScheduler } from "@yornaath/batshit"
import { getArtists } from "../api/ApiClient"
import { useQueries, useQuery } from "@tanstack/react-query"

const artists = create({
    fetcher: getArtists,
    resolver: keyResolver("id"),
    scheduler: windowedFiniteBatchScheduler({
        windowMs: 25,
        maxBatchSize: 100,
    }),
})

export function useArtist(id) {
    return useQuery(getQuery(id))
}

export function useArtists(ids) {
    return useQueries({
        queries: ids.map(getQuery),
    })
}

function getQuery(id) {
    return {
        queryKey: ["artists", Number(id)],
        queryFn: async () => artists.fetch(id),
        throwOnError: true,
    }
}