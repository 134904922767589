import { useParams } from "react-router-dom"
import { formatCount, formatListenDuration, useAutoQuery } from "../utils"
import { getTopArtists } from "../db/DbService"
import React from "react"
import { DateTime } from "luxon"
import { TopArtists } from "../components/TopArtists"
import { TopAlbums } from "../components/TopAlbums"
import { TopSongs } from "../components/TopSongs"
import { TopGenres } from "../components/TopGenres"
import { getDiscoveredArtistCount, getSongCount, getSummary } from "../db/DbClient"
import { useArtists } from "../stores/ArtistStore"
import { Card, CardContent } from "../components/ui/card"
import { Compass, Headphones, Music } from "lucide-react"
import Masonry from "react-masonry-css"

export function YearPage() {
    const {year: yearAsString} = useParams()
    const year = Number(yearAsString)

    const start = DateTime.local(year).startOf("year")
    const end = DateTime.local(year).endOf("year")

    const {data, isPending} = useAutoQuery(getData, {year})

    if(isPending) {
        return
    }

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 w-full">
                <Hero year={year} data={data}/>
                <StatsBar data={data}/>
                <Masonry breakpointCols={{default: 2}} className="flex flex-row gap-4 w-full" columnClassName="flex flex-col gap-8">
                    <TopArtists start={start} end={end}/>
                    <TopAlbums start={start} end={end}/>
                    <TopSongs start={start} end={end}/>
                    <TopGenres start={start} end={end}/>
                </Masonry>
            </div>
        </div>
    )
}

function Hero({year, data}) {
    const artistIds = data.topArtists.map(group => group.entityId)
    const results = useArtists(artistIds)

    const artistsById = {}
    for (const result of results) {
        if (!result.isPending) {
            const artist = result.data
            artistsById[artist.id] = artist
        }
    }

    return (
        <div className="relative overflow-hidden rounded-lg h-[400px]">
            <div className="absolute inset-0 flex">
                {data.topArtists.slice(0, 3).map(group => group.entityId).map((artistId, index) => (
                    <div key={index} className="w-1/3 h-full relative overflow-hidden">
                        <img
                            src={artistsById[artistId]?.images[0]?.url}
                            alt={artistId}
                            className="absolute inset-0 w-full h-full object-cover"
                            style={{
                                filter: 'brightness(0.7) contrast(1.2)',
                                transform: 'scale(1.1)',
                            }}
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"/>
                    </div>
                ))}
            </div>
            <div className="relative h-full flex flex-col justify-end p-8 text-white z-10">
                <h2 className="text-5xl font-semibold">Your <b>{year}</b> Soundtrack</h2>
                <p className="text-2xl font-light opacity-90">Relive the moments that defined your year</p>
            </div>
        </div>
    )
}

function StatsBar({data}) {
    return (
        <div className="grid grid-cols-3 gap-4">
            <Card className="bg-indigo-100 dark:bg-indigo-900">
                <CardContent className="flex flex-col items-center justify-center p-6">
                    <Headphones className="h-12 w-12 mb-2 text-indigo-600 dark:text-indigo-400"/>
                    <p className="text-3xl font-bold">{formatListenDuration(data.durationMs)}</p>
                    <p className="text-sm opacity-80">Listened</p>
                </CardContent>
            </Card>
            <Card className="bg-purple-100 dark:bg-purple-900">
                <CardContent className="flex flex-col items-center justify-center p-6">
                    <Music className="h-12 w-12 mb-2 text-purple-600 dark:text-purple-400"/>
                    <p className="text-3xl font-bold">{formatCount(data.songCount)}</p>
                    <p className="text-sm opacity-80">Tracks Played</p>
                </CardContent>
            </Card>
            <Card className="bg-pink-100 dark:bg-pink-900">
                <CardContent className="flex flex-col items-center justify-center p-6">
                    <Compass className="h-12 w-12 mb-2 text-pink-600 dark:text-pink-400"/>
                    <p className="text-3xl font-bold">{formatCount(data.discoveredArtistCount)}</p>
                    <p className="text-sm opacity-80">New Artists</p>
                </CardContent>
            </Card>
        </div>
    )
}

async function getData({year}) {
    const start = DateTime.local(year).startOf("year")
    const end = DateTime.local(year).endOf("year")

    const summaryPromise = getSummary({start, end})
    const songCountPromise = getSongCount({start, end})
    const discoveredArtistCountPromise = getDiscoveredArtistCount({start, end})
    const topArtistsPromise = getTopArtists({start, end, limit: 3})

    const result = {}

    result.durationMs = (await summaryPromise).durationMs
    result.songCount = await songCountPromise
    result.discoveredArtistCount = await discoveredArtistCountPromise
    result.topArtists = await topArtistsPromise

    return result
}