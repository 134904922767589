import React from "react"
import { useAutoQuery, useIsMobile } from "../utils"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { ArtistWithStatsCard } from "./ArtistWithStatsCard"
import { getTopArtists } from "../db/DbService"

export function TopArtists({genreId, start, end}) {
    const isMobile = useIsMobile()
    const columns = isMobile ? 2 : 3
    const {data: groups, isPending} = useAutoQuery(getTopArtists, {
        genreId,
        start,
        end,
        limit: columns * 3
    })

    if (isPending) {
        return
    }


    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Top Artists</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center gap-4">
                <div className={`grid grid-cols-${columns} gap-2`}>
                    {groups.map(group => <ArtistWithStatsCard group={group} key={group.entityId}/>)}
                </div>
            </CardContent>
        </Card>
    );
}
