import { create } from "zustand"
import { getPlayerState } from "../api/ApiClient"
import { tokenStore } from "./ApiTokenStore"
import { DateTime, Duration, Interval } from "luxon"

export const playerStateStore = create(_ => ({
    songId: null,
    positionMs: null,
    paused: null,
    retrievedAt: Date.now()
}))

export async function update() {
    const {hasToken} = tokenStore.getState()

    if(!hasToken) {
        playerStateStore.setState({})
        return
    }

    const playerState = await getPlayerState()
    playerState.retrievedAt = DateTime.now()
    playerState.positionMsAtRetrieval = playerState.positionMs

    playerStateStore.setState(playerState)
}

export async function updateIfFocussed() {
    if(document.hasFocus()) {
        await update();
    }
}

export async function interpolate() {
    const state = playerStateStore.getState()

    if(state.paused === null || state.paused) {
        return
    }

    const interval = Interval.fromDateTimes(state.retrievedAt, DateTime.now())
    const newState = { ...state }
    newState.positionMs = state.positionMsAtRetrieval + interval.length("milliseconds")

    playerStateStore.setState(newState)
}

tokenStore.subscribe(update)

setInterval(updateIfFocussed, Duration.fromObject({seconds: 10}).toMillis())
setInterval(interpolate, 100)

update()