import React from "react"
import { Link, useParams } from "react-router-dom"
import { OpenInSpotifyButton } from "../../components/OpenInSpotifyButton"
import { ArtistRankCard } from "../../components/ArtistRankCard"
import { ArtistListeningTimeCard } from "../../components/ArtistListeningTimeCard"
import { ArtistTotalListensCard } from "../../components/ArtistTotalListensCard"
import { formatDateLong, getArtistName, useAutoQuery } from "../../utils"
import { useArtist } from "../../stores/ArtistStore"
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar"
import { Badge } from "../../components/ui/badge"
import { DiscographyTab } from "./DiscographyTab"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs"
import { TimelineTab } from "./TimelineTab"
import { StatsTab } from "./StatsTab"
import { ArtistLongestStreakCard } from "../../components/ArtistLongestStreakCard"
import { getActiveArtistStreak } from "../../db/DbService"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../../components/ui/hover-card"

export function ArtistPage() {
    const {artistId} = useParams()

    return (
        <div className="flex flex-col items-center gap-4 w-full">
            <Header artistId={artistId}/>
            <Summary artistId={artistId}/>
            <Body artistId={artistId}/>
        </div>
    )
}

function Header({artistId}) {
    const {data: artist, isPending} = useArtist(artistId)

    if(isPending) {
        return
    }

    const artistName = getArtistName(artist)

    return (
        <div className="relative overflow-hidden rounded-lg h-[300px] lg:h-[400px] w-full">
            <img
                src={artist.images[0]?.url}
                alt={artist.name}
                className="w-full h-full object-cover brightness-75 contrast-125"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"/>
            <div className="flex flex-col gap-4 absolute bottom-0 left-0 p-8 w-full">
                <div className="flex items-end gap-4 flex-wrap">
                    <Avatar className="h-24 w-24 border-4 border-white">
                        <AvatarImage src={artist.images[0]?.url} alt={artistName}/>
                        <AvatarFallback>{artistName[0]}</AvatarFallback>
                    </Avatar>
                    <div className="flex-grow">
                        <div className="flex flex-row items-center gap-4">
                            <h1 className="text-4xl lg:text-5xl font-bold mb-2 text-white">{artistName}</h1>
                            <StreakBadge artistId={artistId}/>
                        </div>
                        <p className="text-xl lg:text-2xl font-light opacity-90 text-white">
                            123 monthly listeners
                        </p>
                    </div>
                    {artist.spotifyUrl != null ? <OpenInSpotifyButton url={artist.spotifyUrl}/> : null}
                </div>
                <div className="flex flex-wrap gap-2">
                    {artist.genres.map(genre => <GenreBadge genre={genre}/>)}
                </div>
            </div>
        </div>
    )
}

function GenreBadge({genre}) {
    return (
        <Link to={`/genre/${genre.id}`} key={genre.id}>
            <Badge variant="secondary" className="text-sm">
                {genre.name}
            </Badge>
        </Link>
    )
}

function StreakBadge({artistId}) {
    const {data: streak, isPending} = useAutoQuery(getActiveArtistStreak, {artistId})

    if(isPending || streak === null) {
        return
    }

    return (
        <HoverCard>
            <HoverCardTrigger>
                <span className="text-3xl p-2 text-white bg-slate-900 rounded-3xl">
                    🔥{streak.length}
                </span>
            </HoverCardTrigger>
            <HoverCardContent className="flex flex-col">
                <span>Started on {formatDateLong(streak.start)}</span>
            </HoverCardContent>
        </HoverCard>
    )
}

function Summary({artistId}) {
    return (
        <div className="grid w-full gap-4 grid-cols-2 lg:grid-cols-4">
            <ArtistTotalListensCard artistId={artistId}/>
            <ArtistListeningTimeCard artistId={artistId}/>
            <ArtistLongestStreakCard artistId={artistId}/>
            <ArtistRankCard artistId={artistId}/>
        </div>
    )
}

function Body({artistId}) {
    return (
        <Tabs defaultValue="stats" className="w-full">
            <TabsList>
                <TabsTrigger value="stats">Stats</TabsTrigger>
                <TabsTrigger value="discography">Discography</TabsTrigger>
                <TabsTrigger value="timeline">Timeline</TabsTrigger>
            </TabsList>
            <TabsContent value="stats">
                <StatsTab artistId={artistId}/>
            </TabsContent>
            <TabsContent value="discography">
                <DiscographyTab artistId={artistId}/>
            </TabsContent>
            <TabsContent value="timeline">
                <TimelineTab artistId={artistId}/>
            </TabsContent>
        </Tabs>
    )
}