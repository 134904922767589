import { roundedNow, useAutoQuery, useIsMobile } from "../../utils"
import { getTopArtists, getTopSongs } from "../../db/DbService"
import { useMeasure } from "react-use"
import { ArtistBubbles } from "../ArtistBubbles"
import { AlbumBubbles } from "../AlbumBubbles"
import { getTopAlbums } from "../../db/DbClient"
import { SongBubbles } from "../SongBubbles"
import { Link } from "react-router-dom"
import { ChevronRight } from "lucide-react"

export function SummaryBar() {
    const [ref, { width }] = useMeasure()
    const isMobile = useIsMobile()

    if(width === 0) {
        return <div className="w-full" ref={ref}></div>
    }

    const size = isMobile ? width * 0.6 : width / 3.15

    return (
        <div className="flex flex-col gap-1">
            <span className="flex flex-row gap-1 text-lg font-medium">
                Your last 30 days
            </span>
            <div className="flex flex-row min-w-full justify-between gap-2 overflow-x-auto" ref={ref}>
                <TopArtists size={size}/>
                <TopAlbums size={size}/>
                <TopSongs size={size}/>
            </div>
        </div>
    )
}

function TopArtists({size}) {
    const isMobile = useIsMobile()
    const {data: groups, isPending} = useAutoQuery(getTopArtists, {
        start: roundedNow().minus({days: 30}),
        end: roundedNow(),
        limit: isMobile ? 20 : 50,
    })

    if (isPending || groups.length === 0) {
        return
    }

    return <Card
        bubbles={<ArtistBubbles groups={groups} size={size}/>}
        text="Artists"
        to="/top?entity=artist&timeFrame=last30Days"
    />
}

function TopAlbums({size}) {
    const isMobile = useIsMobile()
    const {data: groups, isPending} = useAutoQuery(getTopAlbums, {
        start: roundedNow().minus({days: 30}),
        end: roundedNow(),
        limit: isMobile ? 20 : 50,
    })

    if(isPending || groups.length === 0) {
        return
    }

    return <Card
        bubbles={<AlbumBubbles groups={groups} size={size}/>}
        text="Albums"
        to="/top?entity=album&timeFrame=last30Days"
    />
}

function TopSongs({size}) {
    const isMobile = useIsMobile()
    const {data: groups, isPending} = useAutoQuery(getTopSongs, {
        start: roundedNow().minus({days: 30}),
        end: roundedNow(),
        limit: isMobile ? 20 : 50,
    })

    if(isPending || groups.length === 0) {
        return
    }

    return <Card
        bubbles={<SongBubbles groups={groups} size={size}/>}
        text="Songs"
        to="/top?entity=song&timeFrame=last30Days"
    />
}

function Card({bubbles, text, to}) {
    return (
        <div className="flex flex-col items-center gap-2">
            <div className="rounded-lg border border-gray-200 bg-white shadow-sm overflow-hidden">
                {bubbles}
            </div>
            <Link to={to} className="flex flex-row items-center gap-1">
                <h3 className="text-md lg:text-xl font-medium text-gray-900">{text}</h3>
                <ChevronRight/>
            </Link>
        </div>

    )
}