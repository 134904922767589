import React from "react"
import { Header } from "./Header"
import { DesktopPlayerBar } from "./PlayerBar"

export function DesktopLayout({children}) {
    return (
        <div className="flex flex-col items-center w-full h-[100dvh] bg-purple-50">
            <Header/>
            <div className="flex flex-col flex-grow items-center w-full overflow-y-auto">
                <main className="w-full max-w-6xl p-2 flex-grow">
                    {children}
                </main>
            </div>
            <DesktopPlayerBar/>
        </div>
    );
}
