import { useStoreWithEqualityFn } from "zustand/traditional"
import { databaseStore } from "../stores/DatabaseStore"

export function DatabaseLoadingPage() {
    const {stage} = useStoreWithEqualityFn(databaseStore)

    return (
        <div className="flex flex-col h-full items-center justify-center">
            <div className="text-center">
                <div className="inline-flex mb-4" aria-label="Loading">
                    <div className="w-12 h-12 rounded-full bg-indigo-700 dark:bg-purple-400 opacity-75 animate-ping"></div>
                    <div className="absolute w-12 h-12 rounded-full bg-indigo-700 dark:bg-purple-400"></div>
                </div>
                <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-2">Loading your music insights</h1>
                <p className="text-gray-600 dark:text-gray-400">Please wait while we crunch the numbers...</p>
                <p className="text-gray-600">{stage}</p>
            </div>
        </div>
    )
}