import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import {
    epoch,
    formatCount,
    formatListenDuration,
    getAlbumName,
    getArtistCreditAsString,
    roundedNow,
    useAutoQuery,
} from "../utils"
import { useArtist } from "../stores/ArtistStore"
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar"
import { Pagination } from "../components/Pagination"
import { LoadingPage } from "./LoadingPage"
import { getRankings } from "../db/DbService"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select"
import { DateTime } from "luxon"
import { Separator } from "../components/ui/separator"
import { useAlbum } from "../stores/AlbumStore"
import { useSong } from "../stores/SongStore"
import { Textfit } from "react-textfit"

export function TopPage() {
    const [searchParams, setSearchParams] = useSearchParams()

    const selectedEntity = searchParams.get("entity") || "artist"
    const selectedTimeFrame = searchParams.get("timeFrame") || "allTime"
    const [start, end] = getStartAndEnd(selectedTimeFrame)

    const { data: groups, isPending } = useAutoQuery(getRankings, {
        entity: selectedEntity,
        start,
        end
    })

    const setQueryParams = (newParams) => {
        setSearchParams(prev => {
            for(const key in newParams) {
                const value = newParams[key]
                if(value !== null) {
                    prev.set(key, value)
                } else {
                    prev.delete(key)
                }
            }
            return prev
        })
    }

    if (isPending) {
        return <LoadingPage/>
    }

    const pageSize = 100
    const page = parseInt(searchParams.get("page") ?? "1", 10)
    const pageCount = Math.ceil(groups.length / pageSize)
    const pagedGroups = groups.slice((page - 1) * 100, page * 100)

    if(page > pageCount) {
        setQueryParams({
            "page": 1
        })
    }

    return (
        <div className="flex flex-col items-center gap-2 h-full w-full">
            <div className="flex flex-row items-center gap-1.5 text-2xl font-bold">
                <span className="line-clamp-1">Your top</span>
                <Select value={selectedEntity} onValueChange={value => setQueryParams({entity: value, page: 1})}>
                    <SelectTrigger>
                        <SelectValue/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="artist">artists</SelectItem>
                        <SelectItem value="album">albums</SelectItem>
                        <SelectItem value="song">songs</SelectItem>
                    </SelectContent>
                </Select>
                <span>of</span>
                <Select value={selectedTimeFrame} onValueChange={value => setQueryParams({timeFrame: value, page: 1})}>
                    <SelectTrigger>
                        <SelectValue/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="allTime">all time</SelectItem>
                        <SelectItem value="thisWeek">this week</SelectItem>
                        <SelectItem value="thisYear">this year</SelectItem>
                        <SelectItem value="last30Days">last 30 days</SelectItem>
                        <SelectItem value="year2024">2024</SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <Separator/>
            <div className="flex flex-col gap-1.5 w-full">
                {pagedGroups.map(group => <Row entity={selectedEntity} group={group} key={group.entityId}/>)}
            </div>
            <Pagination page={page} pageCount={pageCount} />
        </div>
    )
}

function Row({group, entity}) {
    switch(entity) {
        case "artist": return <Artist group={group}/>
        case "album": return <Album group={group}/>
        case "song": return <Song group={group}/>
        default: return null
    }
}

function Row0({rank, oldRank, img, title, subtitle, path, count, durationMs}) {
    return (
        <div className="flex flex-row gap-2 w-full h-[3rem] items-center justify-between">
            <div className="flex flex-row items-center gap-3 h-full">
                <div className="w-[2rem] flex-shrink-0">
                    <Textfit mode="single" className="text-right" max={30}>
                        #<b>{rank}</b>
                    </Textfit>
                </div>
                <Link className="flex flex-row gap-2 items-center h-full" to={path}>
                    {img}
                    <div className="flex flex-col justify-center h-full">
                        <span className="text-[1rem] font-semibold line-clamp-1">{title}</span>
                        <span className="text-[0.75rem] text-gray-500 line-clamp-1">{subtitle}</span>
                    </div>
                </Link>
            </div>
            <div className="flex flex-col flex-shrink-0 w-1/4">
                <span className="text-[0.75rem] text-gray-500 line-clamp-1">{formatCount(count)} listens</span>
                <span className="text-[0.75rem] text-gray-500 line-clamp-1">{formatListenDuration(durationMs)}</span>
            </div>
        </div>
    )
}

function Artist({ group }) {
    const { data: artist, isPending } = useArtist(group.entityId)

    if (isPending) {
        return null
    }

    return <Row0
        rank={group.rank}
        oldRank={group.oldRank}
        title={artist.name}
        subtitle="123 followers"
        path={`/artist/${artist.id}`}
        count={group.count}
        durationMs={group.durationMs}
        img={(
            <Avatar className="h-full aspect-square">
                <AvatarImage src={artist.images[0]?.url}/>
                <AvatarFallback>{artist.name[0]}</AvatarFallback>
            </Avatar>
        )}
    />
}

function Album({ group }) {
    const { data: album, isPending } = useAlbum(group.entityId)

    if (isPending) {
        return null
    }

    return <Row0
        rank={group.rank}
        oldRank={group.oldRank}
        title={getAlbumName(album)}
        subtitle={getArtistCreditAsString(album.artistCredit)}
        path={`/album/${album.id}`}
        count={group.count}
        durationMs={group.durationMs}
        img={(
            <img 
                className="h-full aspect-square rounded-md"
                src={album.images[0].url} alt={album.name}
            />
        )}
    />
}

function Song({group}) {
    const {data: song, isPending} = useSong(group.entityId)

    if (isPending) {
        return null
    }

    return <Row0
        rank={group.rank}
        oldRank={group.oldRank}
        title={song.name}
        subtitle={getArtistCreditAsString(song.artistCredit)}
        path={`/song/${song.id}`}
        count={group.count}
        durationMs={group.durationMs}
        img={(
            <img
                className="h-full aspect-square rounded-md"
                src={song.images[0].url} alt={song.name}
            />
        )}
    />
}

function getStartAndEnd(timeframe) {
    if(timeframe.startsWith("year")) {
        const year = parseFloat(timeframe.substring(4))
        return [
            DateTime.fromObject({ year }).startOf("year"),
            DateTime.fromObject({ year }).endOf("year")
        ]
    }

    switch(timeframe) {
        case "allTime": return [epoch(), roundedNow()]
        case "thisWeek": return [roundedNow().startOf("week"), roundedNow()]
        case "thisYear": return [roundedNow().startOf("year"), roundedNow()]
        case "last30Days": return [roundedNow().minus({days: 30}), roundedNow()]
        default: throw new Error(`Unknown timeframe "${timeframe}"`)
    }
}
