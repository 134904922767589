import { formatCount, formatListenDuration, getArtistName } from "../utils"
import { Link } from "react-router-dom"
import { BarChart3, ClockIcon } from "lucide-react"
import React from "react"
import { Badge } from "./ui/badge"
import { useArtist } from "../stores/ArtistStore"

export function ArtistWithStatsCard({group}) {
    const {data: artist, isPending} = useArtist(group.entityId)

    if (isPending) {
        return
    }

    const name = getArtistName(artist)

    return (
        <Link to={`/artist/${artist.id}`} className="flex flex-col">
            <div className="rounded-t-md border-t border-x">
                <img src={artist.images[0]?.url} alt={name} className="rounded-t-md w-full object-cover aspect-square"/>
            </div>
            <div className="flex flex-col gap-1 border rounded-b-md p-2">
                <span className="text-md font-semibold text-nowrap truncate">{name}</span>
                <Badge variant="secondary">
                    <BarChart3 className="w-3 h-3"/>
                    <span>{formatCount(group.count)} plays</span>
                </Badge>
                <Badge variant="secondary">
                    <ClockIcon className="w-3 h-3"/>
                    <span>{formatListenDuration(group.durationMs)}</span>
                </Badge>
            </div>
        </Link>
    )
}