import React from "react"
import { useAutoQuery, useIsMobile } from "../utils"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { SongWithStatsCard } from "./SongWithStatsCard"
import { getTopSongs } from "../db/DbService"

export function TopSongs({artistId, albumId, genreId, start, end, discoveredAfter}) {
    const isMobile = useIsMobile()
    const columns = isMobile ? 2 : 3
    const {data: groups, isPending} = useAutoQuery(getTopSongs, {
        artistId,
        albumId,
        genreId,
        start,
        end,
        discoveredAfter,
        limit: columns * 3,
    })

    if (isPending || groups.length === 0) {
        return null
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Top Tracks</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {groups.map((group, index) =>
                        <SongWithStatsCard group={group} index={index} key={group.entityId}/>)}
                </div>
            </CardContent>
        </Card>
    )
}