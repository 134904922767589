import { create } from "zustand"
import { tokenStore } from "./ApiTokenStore"
import * as duckdb from "@duckdb/duckdb-wasm"
import { readUserDbData } from "../api/ApiService"

const JSDELIVR_BUNDLES = duckdb.getJsDelivrBundles()

export const databaseStore = create((set) => ({
    database: null,
    loading: true,
    refresh: async () => {
        // Clear old database, set state to loading
        set({database: null, loading: true, stage: null})

        const {hasToken} = tokenStore.getState()
        if(!hasToken) {
            set({database: null, loading: false, stage: null})
            return
        }

        const userDbDataFuture = readUserDbData()

        set({database: null, loading: true, stage: "INSTALLING_DUCKDB"})

        const bundle = await duckdb.selectBundle(JSDELIVR_BUNDLES)
        const workerUrl = URL.createObjectURL(
            new Blob([`importScripts("${bundle.mainWorker}");`], {type: "text/javascript"}),
        )
        const worker = new Worker(workerUrl)
        const logger = new duckdb.ConsoleLogger()
        const db = new duckdb.AsyncDuckDB(logger, worker)
        await db.instantiate(bundle.mainModule, bundle.pthreadWorker)
        URL.revokeObjectURL(workerUrl)

        await db.open({
            query: {
                castBigIntToDouble: true,
            },
        })

        set({database: null, loading: true, stage: "BUILDING_BUNDLE"})

        const conn = await db.connect()

        await conn.query(`
            SET GLOBAL timezone = '${Intl.DateTimeFormat().resolvedOptions().timeZone}'
        `)

        const userDbData = await userDbDataFuture

        set({database: null, loading: true, stage: "DOWNLOADING_BUNDLE"})

        await db.registerFileBuffer("file.db", userDbData)

        await conn.query(`
            ATTACH 'file.db' as file (READ_ONLY);
        `)

        await conn.query(`
            COPY FROM DATABASE file TO memory;
        `)

        set({database: null, loading: true, stage: "SETTING_UP_DATABASE"})

        // add macros
        await conn.query(`
            CREATE MACRO top_entities() AS TABLE
            SELECT
                entity_id,
                null as partition,
                COUNT(*) as count,
                SUM(duration_ms) as duration_ms
            FROM listen
            GROUP BY entity_id
            ORDER BY SUM(weight) DESC, count DESC, duration_ms DESC, entity_id ASC;
            
            CREATE MACRO top_n_entities_by_year(n) AS TABLE
            SELECT
                entity_id,
                EXTRACT(YEAR FROM timestamp) as partition,
                COUNT(*) as count,
                SUM(duration_ms) as duration_ms,
                ROW_NUMBER() OVER (PARTITION BY partition ORDER BY count DESC) as rank
            FROM listen
            GROUP BY entity_id, partition
            QUALIFY rank <= n;
            
            CREATE MACRO graph(distinct_count, date_part) AS TABLE
            WITH top_n_entity AS (
               SELECT entity_id
               FROM top_entities()
               LIMIT distinct_count
            ), entity_group AS (
               SELECT
                    entity_id,
                    DATE_TRUNC(date_part, timestamp) as partition,
                    null as rank,
                    COUNT(*) as count,
                    SUM(duration_ms) as duration_ms
               FROM listen
               GROUP BY entity_id, partition
               ORDER BY entity_id, partition
            ), top_n_group AS (
                SELECT eg.*
                FROM entity_group eg
                JOIN top_n_entity tne ON eg.entity_id = tne.entity_id
            ), other_group AS (
                SELECT
                    -1 as entity_id,
                    partition,
                    null as rank,
                    SUM(count) as count,
                    SUM(duration_ms) as duration_ms
                FROM entity_group eg
                LEFT JOIN top_n_entity tne ON eg.entity_id = tne.entity_id
                WHERE tne.entity_id IS NULL
                GROUP BY partition
            )
            SELECT *
            FROM top_n_group
            UNION ALL
            SELECT *
            FROM other_group;
            
            CREATE MACRO song_listen(artist_id := null, album_id := null) AS TABLE
            SELECT *
            FROM song_listen l
            WHERE
                (
                    artist_id IS NULL OR
                    EXISTS (
                        SELECT *
                        FROM song s
                        JOIN artist_credit_artist aca ON s.artist_credit_id = aca.artist_credit_id
                        WHERE 
                            l.song_id = s.id AND
                            aca.artist_id = artist_id
                    )
                ) AND
                (
                    album_id IS NULL OR
                    EXISTS (
                        SELECT *
                        FROM song_album sa
                        WHERE
                            l.song_id = sa.song_id AND
                            sa.album_id = album_id
                    )
                );
                        
            CREATE MACRO album_listen(artist_id := null) AS TABLE
            SELECT *
            FROM album_listen l
            WHERE
                (
                    artist_id IS NULL OR
                    EXISTS (
                        SELECT *
                        FROM album a
                        JOIN artist_credit_artist aca ON a.artist_credit_id = aca.artist_credit_id
                        WHERE 
                            l.album_id = a.id AND
                            aca.artist_id = artist_id
                    )
                );
        `)

        await conn.close()

        set({database: db, loading: false, stage: null})
    },
}))

export function getDatabase() {
    const { database, loading } = databaseStore.getState();
    if (!loading && database) {
        return Promise.resolve(database);
    }

    return new Promise((resolve) => {
        const unsub = databaseStore.subscribe(newState => {
            if (!newState.loading && newState.database) {
                unsub();
                resolve(newState.database);
            }
        });
    });
}

tokenStore.subscribe(_ => databaseStore.getState().refresh())

databaseStore.getState().refresh()
