import React from "react"
import { startSpotifyLogin } from "../api/ApiClient"
import { Button } from "./ui/button"

export function SpotifyLoginButton() {
    const handleLogin = async () => {
        try {
            let response = await startSpotifyLogin()
            window.location.href = response.url
        } catch (error) {
            console.error("Login failed:", error)
        }
    };

    return (
        <Button onClick={handleLogin} variant="outline" className="text-md w-full">
            Login with Spotify
        </Button>
    );
}
