import { useStoreWithEqualityFn } from "zustand/traditional"
import { currentUserStore } from "../stores/CurrentUserStore"
import { UploadSpotifyListeningHistoryWidget } from "../components/UploadSpotifyListeningHistoryWidget"
import { epoch, roundedNow, useAutoQuery } from "../utils"
import { getSpotifyBackfillImports } from "../api/ApiClient"
import { SummaryBar } from "../components/home/SummaryBar"
import { ThisWeekSoFarCard } from "../components/home/ThisWeekSoFarCard"
import { OneHitWondersCard } from "../components/home/OneHitWondersCard"
import Masonry from "react-masonry-css"
import { useBreakpoint } from "../utils/tailwindUtils"
import { JourneyCard } from "../components/home/JourneyCard"
import { BlastFromThePastCard } from "../components/home/BlastFromThePastCard"
import { ByTheDecadeCard } from "../components/home/ByTheDecadeCard"

export function HomePage() {
    const {data: imports, isPending: importsPending} = useAutoQuery(getSpotifyBackfillImports)
    const showImportWidget = !importsPending && imports.length === 0

    return (
        <div className="w-full flex flex-col gap-4">
            <Header/>
            <SummaryBar/>
            <Grid>
                {showImportWidget ? <UploadSpotifyListeningHistoryWidget/> : null}
                <ThisWeekSoFarCard/>
                <JourneyCard/>
                <OneHitWondersCard/>
                <BlastFromThePastCard/>
                <ByTheDecadeCard start={epoch()} end={roundedNow()} limit={10}/>
            </Grid>
        </div>
    )
}

function Header() {
    const {currentUser} = useStoreWithEqualityFn(currentUserStore)

    return (
        <div className="w-full flex flex-col gap-2 bg-gradient-to-r from-purple-500 to-orange-500 p-5 rounded-lg">
            <span className="text-4xl text-white drop-shadow-lg font-bold">Hey there, {currentUser?.displayName}</span>
            <span className="text-xl text-white drop-shadow-lg">Let's dive into your soundtrack</span>
        </div>
    )
}

function Grid({children}) {
    const {isMd} = useBreakpoint("md")
    const {isLg} = useBreakpoint("lg")

    let cols;
    if(isLg) {
        cols = 3
    } else if(isMd) {
        cols = 2
    }  else {
        cols = 1
    }

    return (
        <Masonry breakpointCols={{default: cols}} className="flex flex-row gap-8 w-full" columnClassName="flex flex-col gap-8">
            {children.filter(child => child != null)}
        </Masonry>
    )
}