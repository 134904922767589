import { useArtists } from "../stores/ArtistStore"
import { Bubbles } from "./Bubbles"

export function ArtistBubbles({ groups, size }) {
    const artistIds = groups.map(group => group.entityId);
    const results = useArtists(artistIds);

    const artistsById = {};
    for (const result of results) {
        if (!result.isPending) {
            const artist = result.data;
            artistsById[artist.id] = artist;
        } else {
            return null
        }
    }

    const data = groups
        .map(group => {
            const artist = artistsById[group.entityId];
            return {
                id: artist.id,
                name: artist.name,
                value: group.count,
                imageUrl: artist.images[0]?.url,
                path: `/artist/${artist.id}`
            };
        })

    return <Bubbles data={data} size={size}/>
}
